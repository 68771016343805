import React, { useEffect, useRef, useState } from "react";
import './styles.css'; // Import the custom CSS

const App: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");

    const resizeCanvas = () => {
      if (canvas) {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        drawTrace(cursorPosition.x, cursorPosition.y); // Redraw the initial trace when resizing
      }
    };

    const handleMouseMove = (event: MouseEvent) => {
      const { clientX, clientY } = event;
      setCursorPosition({ x: clientX, y: clientY });
      drawTrace(clientX, clientY);
    };

    const drawTrace = (toX: number, toY: number) => {
      if (ctx && canvas) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Determine the opposite corner
        const corners = [
          { x: 0, y: 0 },
          { x: canvas.width, y: 0 },
          { x: 0, y: canvas.height },
          { x: canvas.width, y: canvas.height },
        ];
        
        const oppositeCornerIndex = (toX > canvas.width / 2 ? 0 : 1) + (toY > canvas.height / 2 ? 0 : 2);
        const { x: fromX, y: fromY } = corners[oppositeCornerIndex];
        
        ctx.strokeStyle = "#00ff00"; // Green color for the trace
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(fromX, fromY);

        // Determine the intermediate points for 90-degree angles
        let currentX = fromX;
        let currentY = fromY;
        const step = 200; // Step size for 90-degree bends

        while (currentX !== toX || currentY !== toY) {
          const dx = toX - currentX;
          const dy = toY - currentY;

          if (Math.abs(dx) > Math.abs(dy)) {
            // Move horizontally
            if (Math.abs(dx) > step) {
              currentX += dx > 0 ? step : -step;
            } else {
              currentX = toX;
            }
          } else {
            // Move vertically
            if (Math.abs(dy) > step) {
              currentY += dy > 0 ? step : -step;
            } else {
              currentY = toY;
            }
          }

          ctx.lineTo(currentX, currentY);
        }

        ctx.lineTo(toX, toY);
        ctx.stroke();

        // Draw full-screen crosshair
        ctx.strokeStyle = "#ff0000"; // Red color for the crosshair
        ctx.lineWidth = 1;
        ctx.beginPath();
        // Vertical line
        ctx.moveTo(toX, 0);
        ctx.lineTo(toX, canvas.height);
        // Horizontal line
        ctx.moveTo(0, toY);
        ctx.lineTo(canvas.width, toY);
        ctx.stroke();
      }
    };

    window.addEventListener("resize", resizeCanvas);
    window.addEventListener("mousemove", handleMouseMove);
    resizeCanvas();

    return () => {
      window.removeEventListener("resize", resizeCanvas);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [cursorPosition.x, cursorPosition.y]);

  return (
    <div className="pcb-board min-h-screen flex items-center justify-start p-8 relative">
      <canvas ref={canvasRef} className="absolute top-0 left-0 z-0" />
      <div className="relative z-10">
        <ul className="pcb-silkscreen text-2xl space-y-4">
          <li><a href="" className="hover:text-green-400">Link 1</a></li>
          <li><a href="" className="hover:text-green-400">Link 2</a></li>
          <li><a href="" className="hover:text-green-400">Link 3</a></li>
          <li><a href="" className="hover:text-green-400">Link 4</a></li>
        </ul>
      </div>
      <div className="absolute bottom-4 right-4 pcb-silkscreen text-m text-white">
        {`X: ${cursorPosition.x}, Y: ${cursorPosition.y}`}
      </div>
    </div>
  );
};

export default App;
